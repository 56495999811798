<template>
  <iframe id="iframe" frameborder="0" @load="getAppToken" allowfullscreen="true"
    :src="url" scrolling="yes" ref="iframe"></iframe>
</template>
<script>
import { clearLoginInfo } from '@/utils'
export default {
  data () {
    return {
      url: '',
      code: '',
      appId: '',
      iframeWindow: null
    }
  },
  created () {
    document.title = this.$route.query.appName
    this.url = this.$route.query.url
    this.appId = this.$route.query.appId
    this.code = this.$route.query.appCode
    window.addEventListener('message', event => {
      if (event.data == 'close' || event.data == 401) {
        this.$router.push('/myApp')
      } else if (event.data.name == 'wxDownload') {
        wx.miniProgram.navigateTo({
          url: '/pages/web/pdfDownload?returnUrl=' + event.data.path
        })
      } else if (event.data == 'toVisit') {
        this.$router.push('/visit')
      } else if (event.data == 'toPatrol') {
        this.$router.push('/patrol')
      } else if (event.data.user == '1') {
        this.$router.push({path: '/userRes-info', query: event.data})
      } else if (event.data.house == '1') {
        this.$router.push({path: '/house-add', query: event.data})
      } else if (event.data.houseMove == '1') {
        this.$router.push({path: '/house-move', query: event.data})
      }else if (event.data.name == 'review') {
        if(event.data.query.dire == 2){ //审核通过
          wx.miniProgram.navigateTo({
            url: '/pages/xiaoheDaily/reviewPassed?id=' + event.data.query.id
          })
        }else{
          wx.miniProgram.navigateTo({  //直接分享
            url: '/pages/xiaoheDaily/share?id=' + event.data.query.id
          })
        }
      }
    })
    this.andRndParam()
  },
  mounted () {
    this.iframeWindow = this.$refs.iframe.contentWindow
    // appToken验证失败时跳到登录页
    window.addEventListener('message', (event) => {
      if (event.data == 401) {
        clearLoginInfo()
        this.$message.error('token验证失败，请重新登录')
        this.$router.push({ name: 'login' })
      }
    })
  },
  methods: {
    getAppToken () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/appToken'),
        method: 'get',
        params: this.$http.adornParams({
          appId: this.appId
        })
      }).then(({data}) => {

        console.log(window.__wxjs_environment, window.__wxjs_environment);
        let windowType = window.__wxjs_environment
        if (data && data.code === 0) {
          // 把appToken传给iframe
          this.iframeWindow.postMessage({
            token: this.$globalData.token,
            appid: this.$globalData.appid,
            domain: this.$globalData.domain2,
            appToken: data.appToken,
            appId: this.appId,
            orgId: this.$globalData.userInfo.orgId,
            orgUserId: this.$globalData.userInfo.userId,
            street: this.$globalData.userInfo.street,
            streetName: this.$globalData.userInfo.streetName,
            userName: this.$globalData.userInfo.userName,
            mobile: this.$globalData.userInfo.mobile,
            country: this.$globalData.userInfo.country,
            isJD: true,
            windowType: windowType,
            depth: 4,
            address: this.$globalData.address,
            syncToken: this.$globalData.token,
            code: this.code
          }, '*')
        } else {
          this.$message.error(data.msg)
          this.errorMsg = data.msg
        }
      })
    },
    /**
     * 添加时间戳参数去除缓存
     */
    andRndParam () {
      if (this.url.indexOf('t=') === -1) {
        if (this.url.indexOf('?') === -1) {
          this.url += '?t=' + new Date().getTime()
        } else {
          this.url += '&t=' + new Date().getTime()
        }
      }
    }
  }
}
</script>
<style scoped>
iframe {
  display: block; /* iframes are inline by default */
  border: none; /* Reset default border */
  height: 100vh !important; /* Viewport-relative units */
  width: 100vw !important;
}
</style>
